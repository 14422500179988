import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyAei25s_wfUs0gwtAQEibBQjPlvS38kzEk",
  authDomain: "ergo-b2687.firebaseapp.com",
  projectId: "ergo-b2687",
  storageBucket: "ergo-b2687.appspot.com",
  messagingSenderId: "1004954781965",
  appId: "1:1004954781965:web:71ba131212e5a7f1fac844",
  measurementId: "G-KYS5FY456H",

 
};

firebase.initializeApp(config);
firebase.firestore();

export default firebase;

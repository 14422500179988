const template = (name) => {
  return ` 

  <div style="margin: 0px auto; max-width: 600px">
    <div>
      <table style="width: 100%" role="presentation" border="0">
        <tbody>
          <tr>
            <td
              style="
                direction: ltr;
                font-size: 0px;
                text-align: center;
                width: 600px;
              "
            >
              <table style="vertical-align: top" role="presentation" border="0">
                <tbody>
                  <tr>
                    <td
                      style="
                        font-size: 0px;
                        width: 600px;
                        padding-right: 0;
                        padding-left: 0;
                        word-break: break-word;
                      "
                    >
                      <table
                        class="mj-full-width-mobile"
                        style="border-collapse: collapse; border-spacing: 0px"
                        role="presentation"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="mj-full-width-mobile"
                              style="
                                width: 600px;
                                background-color: #02305a;
                                padding: 30px 0;
                              "
                            >
                              <div
                                style="
                                  margin-left: auto;
                                  margin-right: auto;
                                  width: 150px;
                                  text-align: center;
                                "
                              >
                                <img
                                  style="
                                    border: 0px;
                                    outline: none;
                                    text-decoration: none;
                                    height: auto;
                                    width: 100%;
                                    font-size: 13px;
                                  "
                                  src="https://apis.beesolar.mx/images/ergosolar/logo192.png"
                                  width="100"
                                  height="auto"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <table style="width: 100%" role="presentation" border="0">
        <tbody>
          <tr>
            <td
              style="
                direction: ltr;
                font-size: 0px;
                text-align: center;
                width: 600px;
              "
            >
              <table style="vertical-align: top" role="presentation" border="0">
                <tbody>
                  <tr>
                    <td
                      style="
                        font-size: 0px;
                        width: 600px;
                        padding-right: 0;
                        padding-left: 0;
                        word-break: break-word;
                      "
                    >
                      <table
                        style="vertical-align: top; width: 100%; height: 272px"
                        role="presentation"
                        border="0"
                      >
                        <tbody>
                          <tr style="height: 50px">
                            <td
                              style="
                                font-size: 0px;
                                padding: 10px 25px;
                                word-break: break-word;
                                width: 91.0035%;
                                height: 50px;
                              "
                            >
                              <div
                                style="
                                  font-family: Poppins, Helvetica, Arial,
                                    sans-serif;
                                  font-size: 20px;
                                  font-weight: 300;
                                  line-height: 30px;
                                  text-align: left;
                                  color: #003366;
                                "
                              >
                                <h1
                                  style="
                                    margin: 0;
                                    font-size: 32px;
                                    line-height: 50px;
                                    font-weight: 400;
                                  "
                                >
                                  Registro exitoso
                                </h1>
                              </div>
                            </td>
                          </tr>
                          <tr style="height: 222px">
                            <td
                              style="
                                font-size: 0px;
                                padding: 10px 25px;
                                word-break: break-word;
                                width: 91.0035%;
                                height: 222px;
                              "
                            >
                              <div
                                style="
                                  font-family: Poppins, Helvetica, Arial,
                                    sans-serif;
                                  font-size: 20px;
                                  font-weight: 300;
                                  line-height: 30px;
                                  text-align: left;
                                  color: #003366;
                                "
                              >
                                Estimado(a):&nbsp; <bold>${name.toUpperCase()}</bold> <br /><br />Su registro
                                se realiz&oacute; con &eacute;xito <br /><br />
                                <p style="font-weight: 400">
                                  Pronto recibirá a este correo la liga
                                  de acceso al evento.
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr style="height: 0px">
                            <td
                              style="
                                font-size: 0px;
                                padding: 10px 25px;
                                word-break: break-word;
                                width: 91.0035%;
                                height: 0px;
                              "
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <table style="width: 100%" role="presentation" border="0">
        <tbody>
          <tr>
            <td
              style="
                direction: ltr;
                font-size: 0px;
                text-align: center;
                width: 600px;
              "
            >
              <table style="vertical-align: top" role="presentation" border="0">
                <tbody>
                  <tr>
                    <td
                      style="
                        font-size: 0px;
                        width: 600px;
                        padding-right: 0;
                        padding-left: 0;
                        word-break: break-word;
                      "
                    >
                      <table
                        class="mj-full-width-mobile"
                        style="border-collapse: collapse; border-spacing: 0px"
                        role="presentation"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="mj-full-width-mobile"
                              style="
                                width: 600px;
                                background-color: #d4d4d4;
                                padding: 0px 0;
                              "
                            >
                              <div
                                style="
                                  background: #d5d5d5;
                                  background-color: #d5d5d5;
                                  margin: 0px auto;
                                  max-width: 600px;
                                "
                              >
                                <table
                                  style="width: 600px"
                                  role="presentation"
                                  border="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          direction: ltr;
                                          font-size: 0px;
                                          padding: 20px 0;
                                          text-align: center;
                                        "
                                      >
                                        <div
                                          class="mj-column-per-100 mj-outlook-group-fix"
                                          style="
                                            font-size: 0px;
                                            text-align: left;
                                            direction: ltr;
                                            display: inline-block;
                                            vertical-align: top;
                                            width: 100%;
                                          "
                                        >
                                          <table
                                            style="vertical-align: top"
                                            role="presentation"
                                            border="0"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="
                                                    font-size: 0px;
                                                    padding: 10px 25px;
                                                    word-break: break-word;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      font-family: Poppins,
                                                        Helvetica, Arial,
                                                        sans-serif;
                                                      font-size: 15px;
                                                      font-weight: 300;
                                                      line-height: 30px;
                                                      text-align: left;
                                                      color: #043768;
                                                    "
                                                  >
                                                    <p style="margin: 0">
                                                      Si tiene alguna pregunta, no
                                                      dude en contactar a los
                                                      organizadores al correo:
                                                      <span
                                                        style="
                                                          text-decoration: underline;
                                                          color: #fff;
                                                        "
                                                        ><a style="color: #043768" href="mailto:claudia.tinoco@ergosolar.mx">claudia.tinoco@ergosolar.mx</a></span
                                                      >
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="mj-full-width-mobile"
                              style="
                                width: 600px;
                                background-color: #d4d4d4;
                                padding: 0px 0;
                              "
                            >
                              <div
                                style="
                                  background: #043768;
                                  background-color: #043768;
                                  margin: 0px auto;
                                  max-width: 600px;
                                "
                              >
                                <table
                                  style="
                                    background: #043768;
                                    background-color: #043768;
                                    width: 100%;
                                  "
                                  role="presentation"
                                  border="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          direction: ltr;
                                          font-size: 0px;
                                          padding: 20px 0;
                                          text-align: center;
                                        "
                                      >
                                        <div
                                          class="mj-column-per-100 mj-outlook-group-fix"
                                          style="
                                            font-size: 0px;
                                            text-align: left;
                                            direction: ltr;
                                            display: inline-block;
                                            vertical-align: top;
                                            width: 100%;
                                          "
                                        >
                                          <table
                                            style="vertical-align: top"
                                            role="presentation"
                                            border="0"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="
                                                    font-size: 0px;
                                                    padding: 10px 25px;
                                                    word-break: break-word;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      font-family: Poppins,
                                                        Helvetica, Arial,
                                                        sans-serif;
                                                      font-size: 16px;
                                                      font-weight: 500;
                                                      line-height: 30px;
                                                      text-align: left;
                                                      color: #ffffff;
                                                    "
                                                  >
                                                    <p style="margin: 0">
                                                      Cont&aacute;ctanos:
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    font-size: 0px;
                                                    padding: 10px 25px;
                                                    word-break: break-word;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      font-family: Poppins,
                                                        Helvetica, Arial,
                                                        sans-serif;
                                                      font-size: 16px;
                                                      font-weight: 500;
                                                      line-height: 30px;
                                                      text-align: left;
                                                      color: #ffffff;
                                                    "
                                                  >
                                                    <p style="margin: 0">
                                                    <a style="color: #ffffff; text-decoration: underline;" href="mailto:optimiza@ergosolar.mx">optimiza@ergosolar.mx</a><br />Whatsapp:
                                                    <a style="color: #ffffff; text-decoration: underline;" href="https://api.whatsapp.com/send?phone=5212227094826">2227094826</a>
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

    
    `;
};

export { template };

import React from "react";
import axios from "axios";

// import emailjs from "@emailjs/browser";
import { Formik, Form, Field } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import swal from "sweetalert";
import { template } from "./html.js";
import firebase from "../../../firebase/Firebase.js";
import Terms from "../../../components/footer/terms/Terms";
import { useParams } from "react-router-dom";





const sendMail = (name, mail) => {
 

  try {
      axios.post("https://correos-node.herokuapp.com/send-mail", {
        mail,
        html: template(name,mail),
        subject: "Ergo SunMeet 2"
      });

  } catch (error) {
      console.log(error);
  }
};

const stylesForm = makeStyles((theme) => ({
  textField: {
    width: "100%",
    marginBottom: "1rem",
  },
  formWrapper: {
    width: "90%",
    margin: "2rem auto 0",
    maxWidth: "25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: "1rem",
    color: "#e66031",
  },
  buttonR: {
    margin: "1rem",
    color: "#FFF",
    backgroundColor: "#e66031",
  },
  div: {
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
}));



const showAlert = () =>
  swal({
    title: "¡Gracias por registrarte a nuestro evento Ergo SunMeet 2!",
    text: `Hemos enviado un correo de confirmación \n\n\n Asegúrate de revisar la carpeta de spam`,
    icon: "success"
  });

const showErrorAlert = () =>
  swal({
    title: "Ha ocurrido un error",
    text: "Revisa los datos e inténtalo nuevamente",
    icon: "error",
  });
const RegisterForm = ({ loading, error, cleanUp }) => {
  const { id } = useParams()

  let ref = id

if (ref === undefined) {
  ref = "industria"
}



  const classes = stylesForm();


  return (
   
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          date: new Date(),
          event: "ErgoSunMeet 2022",
          phone: "",
          ref,
          empresa: "",
          puesto: "",
          terms: false,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = "*Requerido";
          }

          if (!values.email) {
            errors.email = "*Requerido.";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Por favor, ingresa un correo electrónico válido.";
          }

          if (values.phone && values.phone.length !== 10) {
            errors.phone = "Por favor, ingresa un teléfono a 10 dígitos.";
          }
          if (!values.empresa) {
            errors.empresa = "*Requerido";
          }
          if (!values.puesto) {
            errors.puesto = "*Requerido";
          }
          if (!values.terms) {
            errors.terms = "*Requerido";
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const varf = {...values};
          try {
        
            await firebase.firestore().collection("/refersAll").add(varf );
            sendMail(values.name, values.email);    
            setTimeout(() => {
              showAlert();
              setSubmitting(false);
              resetForm();
            }, 200);
          } catch(error) {
            showErrorAlert();
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <div className={classes.formWrapper}>
            <Form className={classes.form}>
              <Field
                component={TextField}
                label="Nombre completo*"
                type="text"
                name="name"
                className={classes.textField}
              />
              <Field
                component={TextField}
                label="Correo electrónico*"
                type="email"
                name="email"
                className={classes.textField}
              />
              <Field
                component={TextField}
                label="Teléfono*"
                type="tel"
                name="phone"
                className={classes.textField}
              />
              <Field
                component={TextField}
                label="Empresa*"
                type="text"
                name="empresa"
                className={classes.textField}
              />
              <Field
                component={TextField}
                label="Puesto*"
                type="text"
                name="puesto"
                className={classes.textField}
              />
              <Field
                component={CheckboxWithLabel}
                Label={{ label: <Terms isBlue={true} /> }}
                color="primary"
                type="checkbox"
                name="terms"
              />
              <div className={classes.div}>
                <Button
                  className={classes.buttonR}
                  type="submit"
                  variant="contained"
                  color="primary"
                  data-callback="onSubmit"
                  data-action="submit"
                  disabled={isSubmitting || !isValid || loading}
                >
                  Registrarme
                </Button>
              </div>

              <Typography color="primary" variant="body1" gutterBottom>
                <b>{error === false ? "Registro completado" : null}</b>
              </Typography>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default RegisterForm;

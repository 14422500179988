import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ErgoApp_1 from "../../../static/images/image1.jpg";
import ErgoApp_2 from "../../../static/images/image3.jpg";
import ErgoApp_3 from "../../../static/images/image2.jpg";
import ErgoApp_4 from "../../../static/images/psolar.jpg";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

const Image = styled.img`
  width: 90%;
  height: auto;
  margin-bottom: 1rem;
`;

const Bold = styled.b`
  font-weight: 600;
  justify-content: left;
  align-items: left;
  text-align: left;
`;

const ContentCenter = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: left;
  justify-items: left;
  height: 100%;
`;
const ContentGrid = styled.div`
  display: flex;
  text-align: center;
  flex-direction: row;
  height: 100%;
`;

const Functions = () => {
  return (
    <>
      <Wrapper>
        {/*#region Tecnologias*/}
        <Grid container spacing={4} className="App-functions">
          <Grid item xs={12} sm={12} md={6}>
            <Image src={ErgoApp_1} alt="Nuevas tecnologías" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContentGrid>
              {/* <IconFont src={ErgoTrust} alt="icono ergotrust ergosolar" /> */}
              <ContentCenter>
                <Typography color="textPrimary" variant="h4">
                  <Bold>Economía industrial postpandemia.</Bold>
                </Typography>
                <Typography color="textSecondary" variant="h5" gutterBottom>
                  <Bold>
                    Speaker:
                      Dr. Herberto Rodríguez Regordosa / IESDE School of Management.
                    <br />
                    Duración: 35 min.
                  </Bold>
                </Typography>
              </ContentCenter>
            </ContentGrid>
          </Grid>
        </Grid>
        {/*#end region*/}

        {/*#region BeeSmartEnergy*/}
        <Grid container spacing={4} className="App-functions">
          <Grid item xs={12} sm={12} md={6}>
            <ContentGrid>
              {/* <IconFont src={Ahorro} alt="icono ergotrust ergosolar" /> */}
              <ContentCenter>
                <Typography color="textPrimary" variant="h4">
                  <Bold>ESG tendencias environment, social & governance</Bold>
                </Typography>
                <Typography color="textSecondary" variant="h5" gutterBottom>
                  <Bold>
                    Speaker: Ing. Nayeli Sánchez Moreno / Thyssenkrupp  <br />
                    Duración: 35 min.
                  </Bold>
                </Typography>
              </ContentCenter>
            </ContentGrid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Image src={ErgoApp_3} alt="Nuevas tecnologías" />
          </Grid>
        </Grid>
        {/*#endregion*/}

        {/*#region Transmision*/}
        <Grid container spacing={4} className="App-functions">
          <Grid item xs={12} sm={12} md={6}>
            <Image src={ErgoApp_2} alt="Nuevas tecnologías" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContentGrid>
              {/* <IconFont src={Comunidad} alt="icono ergotrust ergosolar" /> */}
              <ContentCenter>
                <Typography color="textPrimary" variant="h4">
                  <Bold>Oportunidades políticamente viables para ahorrar energía.</Bold>
                </Typography>
                <Typography color="textSecondary" variant="h5" gutterBottom>
                  <Bold>
                    Speaker: Mtro. Luis Stone / CEO ErgoSolar <br />
                    Duración: 35 min.
                  </Bold>
                </Typography>
              </ContentCenter>
            </ContentGrid>
          </Grid>
        </Grid>
        <Grid container spacing={4} className="App-functions">
          <Grid item xs={12} sm={12} md={6}>
            <ContentGrid>
              {/* <IconFont src={Ahorro} alt="icono ergotrust ergosolar" /> */}
              <ContentCenter>
                <Typography color="textPrimary" variant="h4">
                  <Bold>Entrega de premio a proyecto solar del año: ErgoSolar</Bold>
                </Typography>
                <Typography color="textSecondary" variant="h5" gutterBottom>
         
                </Typography>
              </ContentCenter>
            </ContentGrid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Image src={ErgoApp_4} alt="Nuevas tecnologías" />
          </Grid>
        </Grid>
        {/*#end region*/}
      </Wrapper>
    </>
  );
};

export default Functions;
